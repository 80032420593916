import logo from './oev-logo-wallet-w.png';
import './App.css';
import PosaTable from './PosaTable';

function App() {
  document.body.style.background = '#282c34';

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo2" alt="logo" width="250px" height="52px" />
      </header>
      <div className="App-content">
        <PosaTable />
      </div>
    </div>
  );
}

export default App;
