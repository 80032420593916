import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

const CustomButtonComponent = (props) => {
  return <button onClick={() => window.alert('clicked') }><span class='ag-icon-cancel'/></button>;
};

function PosaTable() {
  const [data, setData] = useState([]);

  // const [cols, setCols] = useState([
  //   { field: "id", type: 'leftAligned' },
  //   { field: "created" },
  //   { field: "partner_id" },
  //   { field: "partner_name" },
  //   { field: "customer_id" },
  //   { field: "customer_vorname" },
  //   { field: "customer_nachname" },
  // ]);

  const [cols, setCols] = useState([
    {
      headerName: "ID",
      field: "id",
      maxWidth: 80,
      //colSpan: colSpan,
    },
    {
      headerName: "Created",
      field: "created",
      //cellDataType: 'dateString',
      minWidth: 200,
      //colSpan: colSpan,
    },
    {
      headerName: "öVU Partner",
      headerStyle: { fontWeight: 'bold' },
      children: [
        {
          headerName: "ID",
          field: "partner_id",
          maxWidth: 80,
          flex: 1,
        },
        {
          headerName: "Partnern",
          field: "partner_name",
          flex: 1,
        },
      ],
    },
    {
      headerName: "Kunde",
      children: [
        {
          headerName: "Kd-Nr.",
          field: "customer_id",
          maxWidth: 80,
          flex: 1,
        },
        {
          headerName: "Vorname",
          field: "customer_vorname",
          flex: 1,
        },
        {
          headerName: "Nachname",
          field: "customer_nachname",
          flex: 1,
        },
      ],
    },
    {
      headerName: "",
      field: "button", cellRenderer: CustomButtonComponent,
      maxWidth: 110,
      flex: 1,
    }
  ]);


  //const GridExample = () => {
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([
      { make: "Tesla", model: "Model Y", price: 64950, electric: true },
      { make: "Ford", model: "F-Series", price: 33850, electric: false },
      { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    ]);
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
      { field: "make" },
      { field: "model" },
      { field: "price" },
      { field: "electric" }
    ]);
   //}

  useEffect(() => {
    //axios.get(`https://jsonplaceholder.typicode.com/users`)
    axios.get(`https://posa.kias.cloud/items`,{ crossdomain: true })
      .then(res => {
        setData(res.data);
        console.log(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  },[]);

  return (
    <React.Fragment>
      {/* <div>PosaTable</div>
      <ul>
        {
          data.map(item => (<li key={item.id}>{item.created} &nbsp;&nbsp;&nbsp; ({item.partner_id}) {item.partner_name} &nbsp;&nbsp;&nbsp; {item.customer_vorname} {item.customer_nachname}</li>))
        }
      </ul> */}
      <h4 style={{margin: '20px 20px'}}>öVU Dashboard</h4>
        <div
          className="ag-theme-quartz-dark" // applying the Data Grid theme
          style={{ height: 600, margin: '20px 20px' }} // the Data Grid will fill the size of the parent container
        >
        <AgGridReact
            rowData={data}
            columnDefs={cols}
        />
        </div>
    </React.Fragment>
  )
}

export default PosaTable;